"use client";
import React, { ElementType } from "react";
import cn from "clsx";

export type Variant = "primary" | "secondary" | "focused" | "text" | "icon";
export type Sizes = "large" | "medium" | "small";
export type Colors = "default" | "primary" | "success" | "danger" | "light";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Variant;
  size?: Sizes;
  color?: Colors;
  children: React.ReactNode;
  className?: string;
  submit?: boolean;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  loading?: boolean;
  loadingContent?: React.ReactNode;
  isDisabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onPressStart?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onPressEnd?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onPressChange?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
  innerClassName?: string;
}

const Button = (props: Props, ref: any) => {
  const {
    variant = "primary",
    size = "medium",
    color = "primary",
    children,
    className,
    submit = false,
    fullWidth = false,
    isDisabled = false,
    loading = false,
    onKeyDown,
    onKeyUp,
    onClick,
    onPressChange,
    onPressEnd,
    onPressStart,
    onKeyPress,
    startIcon,
    endIcon,
    loadingContent,
    innerClassName = "",
    ...rest
  } = props;
  return (
    <button
      ref={ref}
      onClick={onClick}
      onChange={onPressChange}
      onMouseDown={onPressStart}
      onMouseUp={onPressEnd}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onKeyPress={onKeyPress}
      disabled={loading || isDisabled}
      type={submit ? "submit" : "button"}
      {...rest}
      className={cn(
        `flex items-center justify-center transition duration-300 hover:scale-[0.98] select-none`,
        { "w-fit": !fullWidth },
        { "w-full": fullWidth },
        {
          "px-4 py-3 rounded-[4px] text-buttonLg gap-2":
            size === "large" && variant != "icon",
        },
        {
          "px-3 py-3 rounded-[4px] text-buttonLg gap-2":
            size === "large" && variant == "icon",
        },
        {
          "px-4 py-2 rounded-[4px] text-buttonMd gap-2":
            size === "medium" && variant != "icon",
        },
        {
          "px-[10px] py-[10px] rounded-[4px] text-buttonMd gap-2":
            size === "medium" && variant == "icon",
        },
        {
          "px-3 py-[6px] rounded-[4px] text-button gap-1":
            size === "small" && variant != "icon",
        },
        {
          "px-2 py-2 rounded-[4px] text-button gap-1":
            size === "small" && variant == "icon",
        },
        {
          "bg-extended_orange-B500 text-extended_orange-B50 hover:bg-extended_orange-B600 active:bg-extended_orange-B600 disabled:bg-extended_orange-B100":
            color === "primary" &&
            (variant === "primary" || variant === "icon"),
        },
        {
          "border border-extended_orange-B500 text-extended_orange-B500 hover:border-extended_orange-B600 active:border-extended_orange-B600 disabled:border-extended_orange-B100 disabled:text-extended_orange-B100":
            color === "primary" && variant === "secondary",
        },
        {
          "bg-extended_orange-B500 text-extended_orange-B50 hover:bg-extended_orange-B600 active:bg-extended_orange-B600 disabled:bg-extended_orange-B100 disabled:ring-extended_orange-B100 ring-[2px] ring-extended_orange-B300":
            color === "primary" && variant === "focused",
        },
        {
          "text-extended_orange-B500 hover:text-extended_orange-B100 hover:bg-extended_orange-B600 active:text-extended_orange-B600 disabled:text-extended_orange-B100 disabled:hover:text-extended_orange-B50 disabled:hover:bg-extended_orange-B100":
            color === "primary" && variant === "text",
        },
        {
          "bg-extended_neutral-N100 text-extended_neutral-N700 hover:bg-extended_neutral-N700 hover:text-extended_neutral-N100 active:bg-extended_neutral-N600 disabled:bg-extended_neutral-N100 disabled:text-extended_neutral-N0":
            color === "light" && (variant === "primary" || variant === "icon"),
        },
        {
          "border border-extended_orange-B500 text-extended_orange-B500 hover:border-extended_orange-B600 active:border-extended_orange-B600 disabled:border-extended_orange-B100 disabled:text-extended_orange-B100":
            color === "light" && variant === "secondary",
        },
        {
          "bg-extended_orange-B500 text-extended_orange-B50 hover:bg-extended_orange-B600 active:bg-extended_orange-B600 disabled:bg-extended_orange-B100 disabled:ring-extended_orange-B100 ring-[2px] ring-extended_orange-B300":
            color === "light" && variant === "focused",
        },
        {
          "text-extended_orange-B500 hover:text-extended_orange-B100 hover:bg-extended_orange-B600 active:text-extended_orange-B600 disabled:text-extended_orange-B100 disabled:hover:text-extended_orange-B50 disabled:hover:bg-extended_orange-B100":
            color === "light" && variant === "text",
        },
        {
          "bg-extended_neutral-N600 text-extended_neutral-N50 hover:bg-extended_neutral-N700 active:bg-extended_neutral-N700 disabled:bg-extended_neutral-N100":
            color === "default" &&
            (variant === "icon" || variant === "primary"),
        },
        {
          "border border-extended_neutral-N600 text-extended_neutral-N700 hover:border-extended_neutral-N700 active:border-extended_neutral-N700 disabled:border-extended_neutral-N200 disabled:text-extended_neutral-N200":
            color === "default" && variant === "secondary",
        },
        {
          "bg-extended_neutral-N600 text-extended_neutral-N0 hover:bg-extended_neutral-N700 hover:ring-extended_neutral-N400 active:bg-extended_neutral-N700 active:ring-extended_neutral-N400 disabled:bg-extended_neutral-N100 disabled:text-extended_neutral-N0 ring-[2px] ring-extended_neutral-N300 disabled:ring-extended_neutral-N100":
            color === "default" && variant === "focused",
        },
        {
          "text-extended_neutral-N600 hover:text-extended_neutral-N0 hover:bg-extended_neutral-N600 active:text-extended_neutral-N700 disabled:text-extended_neutral-N100 disabled:hover:text-extended_neutral-N0 disabled:hover:bg-extended_neutral-N200":
            color === "default" && variant === "text",
        },
        {
          "bg-extended_green-G500 text-extended_green-G0 hover:bg-extended_green-G600 active:bg-extended_green-G600 disabled:bg-extended_green-G50":
            color === "success" &&
            (variant === "primary" || variant === "icon"),
        },
        {
          "border border-extended_green-G500 text-extended_green-G500 hover:border-extended_green-G600 active:border-extended_green-G600 disabled:border-extended_green-G50 disabled:text-extended_green-G50":
            color === "success" && variant === "secondary",
        },
        {
          "bg-extended_green-G500 text-extended_green-G0 hover:bg-extended_green-G600 active:bg-extended_green-G600 disabled:bg-extended_green-G50 ring-[2px] ring-extended_green-G100 disabled:ring-extended_green-G50":
            color === "success" && variant === "focused",
        },
        {
          "text-extended_green-G500 hover:text-extended_green-G0 hover:bg-extended_green-G600 active:text-extended_green-G600 disabled:text-extended_green-G50 disabled:hover:bg-extended_green-G50 disabled:hover:text-extended_green-G0":
            color === "success" && variant === "text",
        },
        {
          "bg-extended_red-R400 text-extended_red-R50 hover:bg-extended_red-R500 active:bg-extended_red-R500 disabled:bg-extended_red-R100":
            color === "danger" && (variant === "primary" || variant === "icon"),
        },
        {
          "border border-extended_red-R400 text-extended_red-R400 hover:border-extended_red-R500 active:border-extended_red-R500 disabled:border-extended_red-R100 disabled:text-extended_red-R100":
            color === "danger" && variant === "secondary",
        },
        {
          "bg-extended_red-R400 text-extended_red-R50 hover:bg-extended_red-R500 hover:ring-extended_red-R400 active:bg-extended_red-R500 disabled:bg-extended_red-R100 ring-[2px] ring-extended_red-R300 disabled:ring-extended_red-R100":
            color === "danger" && variant === "focused",
        },
        {
          "text-extended_red-R400 hover:text-extended_red-R50 active:text-extended_red-R500 hover:bg-extended_red-R400 disabled:text-extended_red-R100 disabled:hover:text-extended_red-R50 disabled:hover:bg-extended_red-R100":
            color === "danger" && variant === "text",
        },
        className
      )}
    >
      {loading ? (
        loadingContent ? (
          loadingContent
        ) : (
          "Loading"
        )
      ) : (
        <span
          className={`flex items-center justify-center gap-1 ${innerClassName}`}
        >
          {startIcon}
          {children}
          {endIcon}
        </span>
      )}
    </button>
  );
};

export default React.forwardRef(Button);
