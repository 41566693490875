import React, { ElementType } from "react";

type Variant =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "paragraph"
  | "small"
  | "button"
  | "caption";

type Align = "left" | "center" | "right" | "justify" | "start" | "end";

interface Props {
  variant?: Variant;
  children: React.ReactNode;
  className?: string;
  align?: Align;
  as?: ElementType;
  onClick?: () => void;
}

const sizes: Record<Variant, string> = {
  h1: "text-heading1 tablet:text-heading1Md laptop:text-heading1Lg",
  h2: "text-heading2 tablet:text-heading2Md laptop:text-heading2Lg",
  h3: "text-heading3 tablet:text-heading3Md laptop:text-heading3Lg",
  h4: "text-heading4 tablet:text-heading4Md laptop:text-heading4Lg",
  h5: "text-heading5 tablet:text-heading5Md laptop:text-heading5Lg",
  h6: "text-heading6 tablet:text-heading6Md laptop:text-heading6Lg",
  paragraph: "text-paragraph  tablet:text-paragraphMd  laptop:text-paragraphLg",
  small: "text-small tablet:text-smallMd laptop:text-smallLg",
  button: "text-button tablet:text-buttonMd laptop:text-buttonLg",
  caption: "text-caption tablet:text-captionMd laptop:text-captionLg",
};
const aligns: Record<Align, string> = {
  left: "text-left",
  center: "text-center",
  right: "text-right",
  justify: "text-justify",
  start: "text-start",
  end: "text-end",
};
const Text = (props: Props) => {
  const {
    variant = "paragraph",
    children,
    className = "",
    as = "p",
    align = "left",
    onClick,
  } = props;
  const sizeClasses = sizes[variant];
  const Tag = as;
  return (
    <Tag
      onClick={onClick}
      className={`${sizeClasses} ${className} ${aligns[align]}`}
    >
      {children}
    </Tag>
  );
};

export default Text;
