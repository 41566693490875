"use client"; // Error components must be Client Components

import { XMarkNormal } from "@/components/icons";
import Button from "@/components/ui/Buttons/Button";
import Text from "@/components/ui/Text";
import { usePathname, useRouter } from "next/navigation";
import { useEffect } from "react";
import { env } from "@/utils/env";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  const router = useRouter();

  useEffect(() => {
    const pathname = window.location.href;
    logErrorToSlack(pathname, error);
  }, [error]);

  function logErrorToSlack(path: string, error: Error) {
    fetch("/api/error", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        path: path,
        error: error.message,
        errorInfo: error.stack,
        isApi: false,
      }),
    });
  }

  return (
    <div className="container w-full bg-extended_neutral-N0 rounded-page p-3 flex flex-col gap-3 justify-center items-center">
      <XMarkNormal size={100} className="!text-extended_red-R500" />
      <Text variant="h3" as="h3">
        Bir hatayla karşılaştık.
      </Text>
      <div className="flex gap-2 items-center">
        <Button color="default" onClick={() => router.push("/")}>
          Anasayfaya Dön
        </Button>
        <Button color="danger" onClick={() => reset()}>
          Yeniden Yükle
        </Button>
      </div>
    </div>
  );
}
